.kt-demo-icon  {
    margin-bottom: 20px; 
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;

    .kt-demo-icon__preview {
        display: flex;
        padding-right: 5px;
        flex: 0 0 40px;  

        i {
            line-height: 0;
            vertical-align: middle;
            font-size: 2rem !important; 
        }
    }

    .kt-demo-icon__class {
        color: $alis-demo-icon-color;
        display: flex;
        flex-grow: 1;
        padding: 0;
        font-size: 0.9rem;
        font-weight: 300;
    }

    &:hover {
        background-color: kt-base-color(grey, 1);
    }
}