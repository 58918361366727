@import '../theme/admin/colors';
@import '../theme/admin/variables';

.update-site-dialog {
  position: fixed;
  z-index: 100000;
  right: $padding;
  bottom: $padding;
  width: 250px;
  // height: 150px;
  background-color: lighten($primary, 40);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: $padding;
  padding-top: $padding * 1.5;
  .close {
    position: absolute;
    right: 0px;
    top: -15px;
  }
  .message {
    line-height: 120%;
    font-size: 16px;
  }
  @media screen and (max-width: 460px) {
    width: calc(100% - #{$padding * 2})
  }
}