@import '../../../../../theme/admin/colors';
@import '../../../../../theme/admin/variables';

.kt-header__topbar {
  .mobile-menu {
    display: none;
    background-color: white;
    .btn {
      margin: 2px 0;
      color: $primary;
      background-color: rgba($primary, 0.1);
    }
    i {
      font-size: 12px;
      padding-top: 4px;
      margin-left: 3px;
    }
  }
  .flaticon2-user {
    $height: 30px;
    border-radius: 50%;
    width: $height;
    height: $height;
    padding: 0px 2px 2px 3px;
    border:2px solid $primary;
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .kt-header-mobile--fixed {
    .kt-header__topbar {
      .mobile-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}