
$alis-aside-menu-bg: saturate(darken(#f6f9fd, 0), 10);
$alis-light-link-bg: darken($alis-aside-menu-bg, 3);

$alis-aside-btn-bg: $alis-light-link-bg; // lighten($alis-light-link-bg, 1.5);
$alis-light-link-text: #3d4465;

$primary: #175e00;
$secondary: #175e00;
$error: #ff4133;
$warning: #ffc75d;
$text-dark: #414663;
$text-light: #babfc6;
$dark-blue: #29346f;
$light-background: #f9f9fc;
$light-middle-grey: #e9ecf0;
$info: #009DCE;

$kt-page-bg-color: $light-background;
// Layout Skins
$kt-layout-skins: (
	brand: $secondary, // $primary,
	navy: $dark-blue,
	light: #ffffff
);

$kt-brand-color: map-get($kt-layout-skins, 'brand');

// $config-brand-color: #3bb673;
// $config-color-white-transparent: rgba(white, 0.5);
// $config-brand-color-text: $config-color-white-transparent;
// $config-burger-color: white;
// $config-perfect-scrollbar-skin: white;
// // $config-brand-color-lighten: lighten($config-brand-color, 0.5);
// // $config-brand-color-lighten-2: lighten($config-brand-color, 0.9);
// $config-brand-color-lighten: $config-color-white-transparent;
// $config-brand-color-lighten-2: $config-color-white-transparent;
// $config-link-colored-light: rgba(black, 0.6);

// // KT general colors

// $alis-database-base-color: #f9fbfe;
// $alis-switch-base-color: #e8ebf1;
// $alis-grid-title-color: #5d5b6f;
// $alis-iconbox-title-color: #464457;
// $alis-nav-hover-color: #f4f4f9;
// $alis-notes-content-color: #f7f8fC;
// $alis-notification-title-color: $alis-grid-title-color;
// $alis-notification-item-color: #b3b1c2;
// $alis-user-card-bg-color: #f9fafe;
// $alis-offcanvas-panel-shadow-color: rgba(82,63,105,0.13);;
// $alis-quick-search-icon-color: #f0f0f4;
// $alis-sticky-toolbar-nav-color: $alis-nav-hover-color;
// $alis-subheader-base-color: #959cb6;
// $alis-subheader-input-group-color: #f3f4f6;
// $alis-subheader-input-text-color: #b1b5cb;
// $alis-code-color: #e83e8c;
// $alis-demo-icon-color: #aaa;
// $alis-divider-color: #ebecf1;
// $alis-aside-bg: #f4f5f8;

// // KT widgets

// $alis-widget-2-shadow: rgba(69, 65, 78, 0.06);
// $alis-widget-3-desc-color: #9686fa;
// $alis-widget-22-date-color: #3d4465;

// // KT profile

// $alis-profile-base-font: #b2afc6;
// $alis-profile-main-info-name: #464457;
// $alis-profile-contact-item-text: $alis-profile-base-font;
// $alis-profile-main-info-position: $alis-profile-contact-item-text;
// $alis-profile-contact-icon-whatsup: #45cb9a;
// $alis-profile-contact-icon-twitter: #18c1f8;
// $alis-profile-stats-label-color: $alis-grid-title-color;
// $alis-profile-stats-chart-color: $alis-profile-contact-item-text;

// // bootstrap components, framework

// $alis-accordion-light-border-color: #eeeef4;
// $alis-accordion-collapsed-color: $alis-iconbox-title-color;
// $alis-accordion-collapsed-border-color: #efeff0;
// $alis-accordion-card-body-color: #8d8c9b;
// $alis-button-base-color: #999;
// $alis-pagination-link-color: #928eaa;
$alis-table-light-tr-color: $light-middle-grey;
// $alis-tooltip-dark-bg-color: #2c2e3e;

// // bootstrap variables, that are directly hardcoded by KT theme

// $alis-bootstrap-secondary: #e1e1ef;
// $alis-bootstrap-light: #f8f9fa;
$alis-bootstrap-dark: $text-dark;
// $alis-bootstrap-btn-secondary-hover-bg-color: $alis-aside-bg;
// $alis-bootstrap-table-bg: $alis-bootstrap-btn-secondary-hover-bg-color;

// // KT plugins

// $alis-datatable-base-color: #f3f4f8;
// $alis-datatable-dtr-title-border: #efefef;
// $alis-dropzone-item-bg: #f8f9fc;
// $alis-duallistbox-button-bg: #f2f3f7;
// $alis-duallistbox-item-selected-bg: $alis-duallistbox-button-bg;
// $alis-quill-bg: #333;
// $alis-quill-color: #f8f8f2;
// $alis-tagify-outside: #ddd;

// // KT layout

// $alis-offcanvas-close-icon: #7fc084;

$alis-brand-aside-perfect-scrollbar-skin: white;
$alis-brand-aside-footer: darken($secondary, 5);
$alis-brand-aside-btn-color: rgba(white, 0.4); // #89dd8d;
$alis-brand-aside-btn-hover: darken($secondary, 2);

// $alis-light-aside-perfect-scrollbar-skin: #e0e2f0;
// $alis-light-aside-footer: #f6f7fd;
// $alis-light-aside-btn-color: #a9ddb9;
// $alis-light-aside-btn-hover: $alis-brand-aside-footer;

// $alis-navy-aside-perfect-scrollbar-skin: #637099;
// $alis-navy-aside-footer: #1f2231;
// $alis-navy-aside-btn-color: #2d6b5c;
// $alis-navy-aside-btn-hover: #191b27;

// $alis-aside-btn-bg:#f6f7fd;
// $alis-aside-close-button-bg: #f8f8fb;
// $alis-aside-secondary-close-button-bg: $alis-aside-bg;
// $alis-aside-secondary-icon-bg: $alis-aside-bg;

// $alis-navy-link-bg: #1f2433;
// $alis-navy-link-text: #c0c4d1;
// $alis-navy-link-icons: #687393;
// $alis-navy-submenu-link-text: #989eb3;
// $alis-navy-section-text: #606a8c;
// $alis-navy-minimize-icon-color: #7c87a6;
// $alis-navy-dropdown-icon-color: #8c8ea4;
// $alis-navy-dropdown-text-color: #868aa8;
// $alis-navy-burger-icon-skin: #484f66;

// $alis-light-link-bg: $alis-aside-btn-bg;
// $alis-light-link-text: #3d4465;
// $alis-light-link-icons: #b4e9b8;
// $alis-light-section-text: #a1c3a7;
// $alis-light-burger-icon-skin: #b4cec1;

$alis-brand-link-bg: darken($secondary, 5);
$alis-brand-link-text: #eff2ff;

//SUBMENU tekstas
$alis-brand-submenu-link-text: white; // #bac5fb;

$alis-brand-link-icons: rgba(white, 0.5);
$alis-brand-section-text: rgba(white, 0.3);
$alis-brand-minimize-icon-color: rgba(white, 0.5); // #bcc8ff;
$alis-brand-burger-icon-skin: rgba(#ffffff, 0.8);
// $alis-brand-bg: #262d31;
// $alis-brand-aside-toggler: #4c595f;

// // KT header

// $alis-brand-header-item-hover-bg: #59e784;
// $alis-brand-header-bg: #53e293;
// $alis-brand-header-hor-icon: #88fcbc;

// $alis-light-header-item-icon-color: #b4b8ce;
$alis-light-header-hover-bg: #EFF8F3;
// $alis-light-header-bottom-border: #eff0f6;
// $alis-light-header-link-text: #6c7293;
// $alis-light-header-hor-icon: #c8cde8;
// $alis-light-header-welcome: #959cb6;

// $alis-navy-header-icon-font-color: #6e7899;
// $alis-navy-header-hover-bg: #282f48;
// $alis-navy-header-bg: #53e27e;
// $alis-navy-header-link-text: #c0c4d1;
// $alis-navy-header-hor-icon: #677191;
// $alis-navy-header-welcome: #6b7594;
// $alis-navy-header-topbar-border: #2e3448;

// // KT header config

// $alis-header-base-bg: #2b2a35;
// $alis-header-toolbar-toggler-color: #4e4c5f;

$alis-header-light-link-bg: #EFF8F3;
// $alis-header-light-icon-font-color: #cacad2;
// $alis-header-light-submenu-link-text: #5f6281;
// $alis-header-light-separator-border: #f6f6f9;

// $alis-header-dark-link-bg: #31aa64;
// $alis-header-dark-link-text: #f8f8fb;
// $alis-header-light-icon-font-color: #8c8ea4;

// $alis-header-default-link-text: #868aa8;
// $alis-header-default-offcanvas-close: #968ad4;
// $alis-header-default-separator-border: #f6f6f9;
// $alis-header-default-submenu-link-text: #6b6f8d;

// $alis-header-mobile-toggler-icon: #4e4c5f;
// $alis-header-topbar-welcome: #636177;
// $alis-header-topbar-languages-bg: #212029;
// $alis-header-topbar-tablet-mobile-bg: #2b2a35;

// // OTHER KT variables

// // KT Blog

// $kt-blog-grid-font-color: #918ea7;
// $kt-blog-list-font-color: #918ea7;
// $alis-blog-list-title-color: #464457;
// $alis-blog-list-meta-border: #e9e9f1;
// $kt-blog-post-title-color: #464457;
// $kt-blog-post-font-color: #918ea7;
// $kt-blog-post-content-color: #7b7986;
// $alis-blog-post-date-border: #e9e9f1;

// // KT Error 404

// $kt-error404-v1-title-color: #fb2f73;
// $kt-error404-v3-btn-color: #f7098f;
// $kt-error404-v4-btn-color: #f7098f;

// // KT inbox

// $kt-inbox-icon-color: lighten(#8e96b8, 6%);
// $kt-inbox-hover-bg: #f2f3f7;

// // KT invoice

// $kt-invoice1-font-color: #aaabf7;
// $kt-invoice1-body-title: #babac6;
// $kt-invoice1-body-content: #464457;
// $kt-invoice1-body-table-th-border: #eaebec;

// // KT login

// $alis-login-v1-action-btn: #ff146c;
// $alis-login-v1-options-btn: #1f2375;
// $alis-login-v1-options-btn-hover: #1f2164;

// $alis-login-v2-signup-span: #9d9fb1;
// $alis-login-v2-title: #636576;
// $alis-login-v2-description: #a5a7bb;
// $alis-login-v2-facebook: #3b5998;
// $alis-login-v2-twitter: #1da1f2;
// $alis-login-v2-google: #ea4335;
// $alis-login-v2-link: #9c9fb1;

// // KT Pricing

// $kt-pricing-v1-base-color: #b2afc6;
// $kt-pricing-v1-label-color: #9b98af;
// $kt-pricing-v1-currency-color: #cccce3;
// $alis-pricing-v1-border: #ebebf5;
// $alis-pricing-v1-base-color: #918ea7;
// $alis-pricing-v1-currency-color: #cccce3;
// $alis-pricing-v1-border: #f0f0f6;
// $alis-pricing-v1-btn-color: #7f87aa;
// $alis-pricing-v1-btn-active-bg: #b4aad3;
// $alis-pricing-v1-iconbox-icon: #8388a4;

// // KT Wizards

// $kt-wizard-v1-step-bg: #f6f6f9;
// $kt-wizard-v1-step-number: #9896a6;
// $kt-wizard-v1-font: #918ea7;
// $alis-wizard-border: #eeeef4;

// $kt-wizard-v2-step-bg: #d2d0e0;
// $kt-wizard-v2-step-number: #9896a6;
// $kt-wizard-v2-font: #918ea7;

// $kt-wizard-v3-step-bg: #f6f6f9;
// $kt-wizard-v3-step-font: kt-base-color(label, 1);
// $kt-wizard-v3-label-font: kt-base-color(label, 2);

// State colors
$kt-state-colors: (
  // Keen states
  brand:
  (
    base: $primary,
    inverse: $dark-blue
  ),
  metal: (
    base: #d3dae6,
    inverse: #586272
  ),
  light: (
    base: #ffffff,
    inverse: #282a3c
  ),
  dark: (
    base: $text-dark, // lighten(#4c467a, 12%),
    inverse: #ffffff
  ),
  accent: (
    base: #00c5dc,
    inverse: #ffffff
  ),
  focus: (
    base: #9816f4,
    inverse: #ffffff
  ),
  // Bootstrap states
  primary: (
    base: $primary,
    inverse: white
  ),
  secondary: (
    base: $secondary,
    inverse: white
  ),
  success: (
    base: lighten($primary, 10),
    inverse: #ffffff
  ),
  info: (
    base: #79bbf8,
    inverse: #ffffff
  ),
  warning: (
    base: $warning,
    inverse: #111111
  ),
  danger: (
    base: $error,
    inverse: #ffffff
  ),
);

$kt-base-colors: (
  label: (
    1: #a2a5b9,
    2: #74788d,
    3: #595d6e,
    4: #48465b
  ),
  shape: (
    1: #f0f3ff,
    2: #e8ecfa,
    3: #93a2dd,
    4: #646c9a
  ),
  grey: (
    1: #f7f8fa,
    //#f4f5f8
      2: #ebedf2,
    3: darken(#ebedf2, 3%),
    4: darken(#ebedf2, 6%)
  )
);

// // $kt-social-colors: (
// //     facebook: (
// //         base: #3b5998,
// //         inverse: #ffffff
// //     ),
// //     google: (
// //         base: #dc4e41,
// //         inverse: #ffffff
// //     ),
// //     twitter: (
// //         base: #4AB3F4,
// //         inverse: #ffffff
// //     ),
// //     instagram: (
// //         base: #517fa4,
// //         inverse: #ffffff
// //     ),
// //     youtube: (
// //         base: #b31217,
// //         inverse: #ffffff
// //     ),
// //     linkedin: (
// //         base: #0077b5,
// //         inverse: #ffffff
// //     ),
// //     skype: (
// //         base: #00aff0,
// //         inverse: #ffffff
// //     )
// // );

$kt-font-color: (
  text: $text-dark,
  link: (
    default: $primary,
    hover: darken($primary, 6%)
  )
) !default;
