.table-filter {
  padding: $padding*1.2;
  padding-bottom: $padding*1.2/2;
  background-color: $light-background;
  box-shadow: 0 0 16px rgba(0,0,0,.05);
  .react-datepicker-wrapper {
    display: block;
    .react-datepicker__close-icon {
      top: -6px;
    }
  }
  select, input, textarea {
     margin-bottom: $padding*1.2/2;
  }
  display: flex;
  .filter-content {
    flex-grow: 1;
  }
  .filter-buttons {
    margin-left: $padding;
    margin-bottom: $padding*1.2/2;
    display: flex;
    .btn-filter {
      min-width: 120px;
      width: 120px;
    }
  }
  &.inner {
    box-shadow: none;
    border-color: $light-background;
    background-color: darken($light-background, 2);
    margin: -$padding*1.2 - 1px;
    margin-bottom: $padding * 1.2 / 2;
    @media screen and (max-width: 1024px) {
      margin: -$padding*.7 - 1px;
      margin-bottom: $padding*.7 / 2;
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
    .filter-buttons {
      margin-left: 0;
      .btn-filter {
        min-width: 100%;
        width: 100%;
      }
    }
  }
}
