@import "../../../theme/default/_colors.scss";
@import "../../../theme/default/_keen-original-colors.scss";

.filter-form {
    $margin: 25px;
    $intensity: 33;
    $bg-color: $kt-page-bg-color;
    color: $alis-text;
    font-weight: 400;
    .content {
        margin-top: $margin;
        .table:last-of-type {
            margin-bottom: 0;
        }
        .react-bootstrap-table-pagination {
            margin-top: $margin;
        }
    }
    & + br {
        display: none;
    }
    & > .alis-portlet {
        // margin: -$margin;
        margin-bottom: 0;
        padding-bottom: 0;
        background-color: rgba(23, 94, 0, 0.1);
        box-shadow: none;
        & > .alis-portlet__body {
            padding: 0;
            .filter-block {
                padding: 0 $margin;
                display: flex;
                flex-direction: row;
                &.break-xs {
                    @media (max-width: 767px) {
                        flex-direction: column;
                    }
                }
                &.break-llg {
                    @media (max-width: 1280px) {
                        flex-direction: column;
                    }
                }
                padding-bottom: $margin;
                .button-block {
                    flex-shrink: 1;
                    align-self: flex-end;
                    .btn {
                        margin-left: $margin;
                    }
                }
                .filter-short {
                    padding-top: #{$margin - 10px};
                    flex-grow: 1;
                }
            }
            .button-block {
                display: flex;
                justify-content: flex-end;
                .btn {
                    width: 105px;
                    margin-top: $margin;
                    i.la {
                        padding-right: 0;
                        font-size: 16px;
                        margin-right: -5px;
                    }
                }
            }
            .btn-expand.btn.btn-link {
                background-color: rgba($bg-color, 0.9);
                &,
                &:hover {
                    text-decoration: none;
                    color: $alis-text-intense;
                    border-radius: 0;
                    height: 32px;
                    padding: 0;
                    font-weight: 500;
                }
                &:hover {
                    background-color: darken($bg-color, 2);
                }
                i.la {
                    opacity: 0.5;
                }
            }
            .filter-expanded {
                border-top: 1px solid darken($bg-color, 5);
                padding: 0 $margin;
                padding-top: 10px;
                margin-bottom: $margin;
            }
        }
    }
    .kt-radio,
    .kt-checkbox {
        background-color: rgba($bg-color, 0.5);
        width: 100%;
        padding-left: 40px;
        border-radius: 3px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0px;
        span {
            margin-top: 10px;
            margin-left: 14px;
        }
        &.checked {
            background-color: darken($bg-color, 0.3);
        }
    }
    .letters-condensed {
        letter-spacing: -0.5px;
    }
}

@media (max-width: 1024px) {
    .filter-form {
        & > .alis-portlet {
            margin: -15px;
        }
    }
    .kt-aside__footer.kt-grid__item {
        display: none;
    }
    .kt-aside-menu.kt-scroll {
        height: auto;
    }
    .kt-aside--on .kt-aside-close {
        left: 276px;
        &:before {
            content: " ";
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }
    .kt-aside--on {
        .kt-grid__item.kt-page {
            &:after {
                content: " ";
                height: 100%;
                width: 100%;
                position: fixed;
                background-color: rgba(black, 0.5);
                z-index: 1000;
                top: 0;
            }
        }
    }
}
