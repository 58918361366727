//
// Divider
//

.kt-divider {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 2rem 0;     

    > span {        
        &:first-child {
            width: 100%;
            height: 1px;                 
            flex: 1;
            background: $alis-divider-color;
            display: inline-block;
        }

        &:last-child {
            width: 100%;
            height: 1px;               
            flex: 1;
            background: $alis-divider-color;
            display: inline-block;
        }

        &:not(:first-child):not(:last-child) {
            padding: 0 2rem;                           
        }
    }
}