$kt-page-container-width: 1200px;
$primary: #5fbd86;

input:read-only:focus {
    outline: 0px solid rgba($primary, 0.3);
}

#kt_wrapper {
    .btn.btn-link {
        padding: 0;
        &:hover {
            background-color: transparent;
            color: darken($primary, 10);
        }
    }
}

.fishing-to .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
}
