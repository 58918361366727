//
// Aside Brand Skin
//




// Include framework config
@import "../../../../config";

// Include demo config
@import "../../config";

// Include header config
@import "../../header/config";

// Include aside config
@import "../../aside/config";

// Aside
.kt-aside {
	background-color: kt-get($kt-layout-skins, brand);

	// Scrollbar
	@include kt-perfect-scrollbar-skin($alis-brand-aside-perfect-scrollbar-skin);

	// Footer
	.kt-aside__footer {
		background-color: $alis-brand-aside-footer;

		.btn {
			@include kt-transition();
			background-color: transparent;
			
			i {
				@include kt-transition();
				color: $alis-brand-aside-btn-color;
			}
		}

		.show .btn,
		.btn:hover {
			@include kt-transition();
			background-color: darken($alis-brand-aside-btn-hover, 6%);

			i {
				@include kt-transition();
				color: #ffffff;
			}
		}
	}
}

// Build Aside Menu Skin
@include kt-menu-ver-build-skin(kt-get($kt-aside-config, menu), brand);