.fb_button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    border-width: 0px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0);    
}

.fb_icon{
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-radius: 10%;
    border-color: black;
    min-height: 40px;
    min-width: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.fb_icon.fb_icon_pdf {
    border-color: red;
}
.fb_icon.fb_icon_doc {
    border-color: rgb(2, 7, 248);
}
.fb_icon.fb_icon_xls {
    border-color: rgb(0, 170, 91);
}
.fb_icon.fb_icon_zip {
    border-color: rgb(112, 66, 5);
}
.fb_icon.fb_icon_geojson {
    border-color: rgb(120, 7, 165);
}







.fb_info{
    display: flex;
    flex-direction: column;    
    padding: 5px;
}