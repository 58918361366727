//
//  Code
//



code {
	color: $alis-code-color;
	background: kt-base-color(grey, 1);
	padding: 0.25rem 0.5rem;

	@include kt-rounded {
		border-radius: 2px;
	}
}