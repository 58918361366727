//
// Table
//



.table {

	&.table-bordered {
		border-width: 0;
	}
	thead {
		th, td {
			font-weight: 600;
			border-top-width: 0;
			border-bottom-width: 0;
			color: kt-state-color(secondary, base);
		}
		th {
			border-bottom-width: 1px;
		}
	}
	
	thead, tbody {
		th, td {
			padding: .75rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-color: $kt-page-bg-color;
			border-left-width: 0;
			border-right-width: 0;
			vertical-align: middle;
			color: $text-dark;
		}
	}

	tbody {
		tr{
			.btn-outline-primary {
				&:not(:disabled):not(.disabled).active {
					color: white;
				}
			}
			&:hover {
				// td {
					background: rgba($primary, 0.15);
					// color: map-get($kt-layout-skins, 'brand');
				// }
	
				.btn-outline-primary {
					background-color: rgba($primary, 0.7) !important;
					color: white;
					&:hover {
						background-color: $primary !important;
					}
					&:not(:disabled):not(.disabled).active {
						color: white;
					}
				}
			}
		}
	}

	.react-bs-table-no-data {
		font-size: 24px;
		padding: 50px;
		background-color: white;
		color: rgba($secondary, 0.3);
		border-bottom-width: 0;
		border: 0 solid $kt-page-bg-color;
		border-top-width: 1px !important;
		&, div {
			text-align: center;
		}
		@media screen and (max-width: 1024px) {
			& td, > div {
				width: calc(100vw - #{50px*3});
			}
		}
	}

	tr.table-active {
		td, th {
			color: kt-state-color(secondary, base);
		}
	}

	&.table-head-noborder {
		thead {
			th, td {
				border-top: 0;
			}
		}
	}

	&.table-head-solid {
		thead {
			th, td {
				background-color: $kt-page-bg-color;
			}
		}
	}

	&.table-light{
		tr{
			border-radius: 5px;
			color: $alis-table-light-tr-color;

			&:nth-child(even){
				background-color: $kt-page-bg-color;	
			}	

			td.table-row-title{
				color: kt-base-color(label, 4);
			}
		}

		thead{
			tr{
				background-color: darken($kt-page-bg-color, 2);
				color: kt-state-color(secondary, base);
			}
		}

		@each $name, $color in $kt-state-colors{

			&--#{$name}{
				thead{
					tr{
						color: kt-state-color(#{$name}, base);
						background-color: rgba(kt-state-color(#{$name}, base), 0.15);
					}
				}
			}
		}
	}
	.btn {
		i.la {
			margin-right: 0;
		}
	}
}
.selectable-table {
	.table {
		tr {
			cursor: pointer;
		}
		.selection-cell, .selection-cell-header {
			display: none;
		}
	}
}

.table:not(.table-bordered) {
	thead {
		th, td {
			border-top: 0;
		}
	}
}