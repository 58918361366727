@import '../theme/admin/colors';
@import '../theme/admin/variables';
@import '../theme/admin/checkbox';

.profile {
  $bg-green: lighten($primary, 40);
  display: flex;
  .static-data, .input-data {
    padding: $padding * 2;
  }
  .static-data {
    background-color: rgba($light-middle-grey, 0.4);
    min-width: 300px;
    border-right: 1px solid $light-middle-grey;
  }
  input.form-control {
    
  }
  select.form-control {
    padding: 0rem 1rem;
    height: 45px;
  }
  // TODO: this is a dublicate style from L05ParaiskaEdit.scss
  // check the styles of checkboxes in the theme and remove this dublicate!!!
  @include checkbox;
  @media screen and (max-width: 1024px) {
    display: block;
    .static-data, .input-data {
      padding: $padding * 1.5;
    }
    .static-data {
      width: auto;
      border-right: none;
    }
  }
  @media screen and (max-width: 575px) {
    .static-data, .input-data {
      padding: $padding;
    }
  }
}