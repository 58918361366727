//
// Layout Config
//



// State colors
$kt-state-colors: (
    // Keen states
    brand: (
        base: #5d78ff,
        inverse: #ffffff
    ),
    metal: (
        base: #d3dae6,
        inverse: #586272
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c
    ),
    dark: (
        base: lighten(#4c467a, 12%),
        inverse: #ffffff
    ),
    accent: (
        base: #00c5dc,
        inverse: #ffffff
    ),
    focus: (
        base: #9816f4,
        inverse: #ffffff
    ),

    // Bootstrap states
    primary: (
        base: #5867dd,
        inverse: #ffffff
    ),
    success: (
        base: #1dc9b7,
        inverse: #ffffff
    ),
    info: (
        base: #5578eb,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #fd397a,
        inverse: #ffffff
    )
) !default;

// Page
$kt-page-bg-color: #f2f3f8 !default;

// Layout Skins
$kt-layout-skins: (
	brand: #4d65dc,
	navy: #242939,
	light: #ffffff
) !default;

// Page padding
$kt-page-padding: (
	desktop: 25px,
	mobile: 15px
) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Layout
$kt-aside-default-width: 200px !default;
$kt-aside-minimize-width: 78px !default;
$kt-aside-offcanvas-width: 255px !default;
