@import '../../../../theme/admin/colors';
@import '../../../../theme/admin/variables';

.index-logo {
  cursor: pointer;
  height: 100%;
  background-color: $primary;
  padding: 0 20px;
  font-weight: 700;
  font-size: 18px;
  color: white;
  img {
    width: 72px;
    margin-right: 15px;
  }
}

.kt-header.public {
  .kt-header-menu-wrapper {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1024px) {
  .kt-header-mobile--fixed {
    .kt-header.public {
      top: 0;
      position: fixed;
      z-index: 1000;
      display: flex;
      flex-direction: row;
      vertical-align: center;
      min-width: 100%;
      .index-logo {
        height: auto;
      }
      .kt-header__topbar {
        z-index: 0;
        flex-grow: 1;
        margin-top: auto;
        position: relative;
        .kt-hidden-mobile {
          display: block !important;
        }
      }
    }
  }
}
