//
// Aside Config
//




$kt-header-fixed-zindex: kt-get($kt-header-config, base, desktop, fixed, zindex);

$kt-aside-menu-dropdown-submenu-width: 245px;

$kt-aside-config: (
	base: (
		default: (
			width: $kt-aside-default-width
		),
		fixed: (
			zindex: 98,
			scroll: (
				width: 4px
			)
		),
		minimize: (
			width: $kt-aside-minimize-width,
			transition: all 0.3s ease
		),
		offcanvas-mobile: (
			layout: (
				self: (
					zindex: 1001,
					width: 275px
				),
				close: (
					size: 25px,
					font-size: 1.4rem
				)
			),
			skin: (
				self: (
					bg-color: #ffffff,
					shadow: 0px 1px 9px -3px rgba(0,0,0,0.75)
				),
				overlay: (
					bg-color: rgba(#000000, 0.1),
				),

				close: (
					icon-color: (
						default: $alis-offcanvas-close-icon,
						hover: #ffffff
					),
					bg-color: (
						default: darken($alis-aside-close-button-bg, 5%),
						hover: kt-brand-color()
					)
				)
			)
		)	
	),

	menu: (
		// base parameters required to build the base menu
		base:	(
			class: kt-aside-menu, // menu element class name
			parent-class: kt-aside, // manu parent aside element class name
			mode: general, // menu mode: general or tablet-and-mobile
		),

		// customize the menu
		build: (
			// layout
			layout: (
				// default mode
				default: (
					// menu panel itself
					self: (
						padding: 15px 0
					),

					// root item
					item: (
						// item itself
						self: (
							margin:	0
						),

						// item link
						link: (
							// link itself
							self: (
								height: 44px,				
								padding: 9px 30px
							),					

							// link icon
							icon:	(
								width: 30px,
								font-size: 1.3rem
							),		

							// link bullet
							bullet:	(
								self: (
									width: 15px,
								),

								dot: (
									size: 4px
								),

								line: (
									width: 5px,
									height: 1px
								)							
							),					

							// link text
							text:	(
								font-size: 1rem,
								font-weight: 400,
								font-transform:	initial
							),

							// link arrow
							arrow:	(
								width: 20px,
								transition: all 0.3s ease,
								font-size: 0.7rem
							),

							// link badge
							badge:	(
								padding: 0px 0px 0px 5px
							)
						),

						// item submenu
						submenu: (
							// submenu self
							self: (
								indent: 15px,
								margin:	0
							),

							// submenu item
							item: (
								// item itself
								self: (
									margin:	0
								),

								// item link
								link: (
									// link itself
									self: (
										height: 40px,		
										padding: 0 30px,
										padding-x: 30px
									),					

									// link icon
									icon:	(
										width: 35px,
										font-size: 1.3rem
									),		

									// link bullet
									bullet:	(
										self: (
											width: 20px,
										),
										dot: (
											size: 4px
										),
										line: (
											width: 5px,
											height: 1px
										)							
									),						

									// link text
									text:	(
										font-size: 1rem,
										font-weight: 400,
										font-transform:	initial
									),

									// link arrow
									arrow:	(
										width: 20px,
										transition: all 0.3s ease,
										font-size: 0.7rem
									),

									// link badge
									badge:	(
										padding: 0px 0px 0px 5px
									)
								)
							),

							// submenu section
							section: (
								self: (
									indent: 15px,
									padding: 0 25px,
									margin: 20px 0 0 0,
									height: 40px
								),

								text: (
									font-size: 0.8rem,
									font-weight: 300,
									font-transform: uppercase,
									letter-spacing: 0.3px
								),

								icon: (
									font-size: 1.1rem
								)
							),

							// submenu separator
							separator: (
								margin: 15px 0
							)
						)
					),

					// root section
					section: (
						self: (
							padding: 0 27px,
							margin: 20px 0 0 0,
							height: 40px
						),

						text: (
							font-size: 0.83rem,
							font-weight: 500,
							font-transform: uppercase,
							letter-spacing: 0.3px
						),

						icon: (					
							font-size: 1.1rem
						)
					),

					// root separator
					separator: (
						margin: 15px 0,
					)
				),

				// minimize mode
				minimize: (
					// menu panel itself
					self: (
						padding: 15px 0,
						width: $kt-aside-minimize-width,
					),

					// root item
					item: (
						// self
						self: (
							zindex:	$kt-header-fixed-zindex - 1,
							width: $kt-aside-menu-dropdown-submenu-width + $kt-aside-minimize-width + 1,
						),

						// item link
						link: (
							badge: (
								right: 7px,  
								size: 6px
							)
						),

						// item submenu
						submenu: (
							// self
							self: (
								width: $kt-aside-menu-dropdown-submenu-width,
								margin-left: $kt-aside-minimize-width + 1
							),

							// parent item
							parent-item: (
								// item itself
								self: (
									margin:	0
								),

								// item link
								link: (
									// link itself
									self: (
										height: 40px,			
										margin-bottom: 0px,	
										padding: 0 30px 10px 30px
									),					
									// link text
									text:	(
										font-size: 1.05rem,
										font-weight: 400,
										font-transform:	initial
									),
									// link badge
									badge:	(
										padding: 0px 0px 0px 5px
									)
								)
							)
						)
					),

					// root section
					section: (
						icon: (				
							font-size: 1.1rem,
							padding: 0
						)
					),
				),

				// dropdown mode
				dropdown: (
					// dropdown menu item
					item: (
						// item itself
						self: (
							margin: 0, 
							zindex:	$kt-header-fixed-zindex - 1,
						),

						// submenu
						submenu: (
							// submenu itself
							self: (
								// submenu base styles
								padding: 20px 0,
								width: $kt-aside-menu-dropdown-submenu-width,
								parent-width: $kt-aside-default-width,
								border-radius: 4px,

								// submenu animation
								animation: (
									offset: 10px
								),

								// vertical offset
								offset: (
									root: (
										default: -5px,
										up: -10px
									),
									inner: (
										default: -10px,
										up: -10px
									)								
								)
							),

							// submenu item
							item: (
								// item itself
								self: (
									margin:	0
								),

								// item link
								link: (
									// link itself
									self: (
										height: 40px,				
										padding: 7px 30px
									),					
									// link icon
									icon:	(
										width: 35px,
										font-size: 1.35rem
									),		
									// link bullet
									bullet:	(
										self: (
											width: 20px,
										),
										dot: (
											size: 4px
										),
										line: (
											width: 5px,
											height: 1px
										)							
									),						
									// link text
									text:	(
										font-size: 1rem,
										font-weight: 400,
										font-transform:	initial
									),

									// link arrow
									arrow:	(
										width: 20px,
										transition: all 0.3s ease,
										font-size: 0.7rem
									),

									// link badge
									badge:	(
										padding: 0px 0px 0px 5px,
										align: right,	
									)
								)
							),

							// submenu section
							section: (
								self: (
									margin: 20px 0 0 0,
									padding: 0 25px,
									height: 40px
								),
									
								text: (
									font-size: 0.8rem,
									font-weight: 300,
									font-transform: uppercase,
								),

								icon: (
									font-size: 1.1rem,
								)
							),

							// submenu separator
							separator: (
								margin: 15px 0,
							)
						)
					)
				)		
			),

			// skins
			skins: (
				navy: (
					// default mode
					default: (
						// menu panel itself
						self: (
							bg-color: kt-get($kt-layout-skins, navy)
						),

						// root item
						item: (
							// item itself
							self: (
								bg-color: (
									default: null,
									open: null,
									here: null,
									active: null,
									hover: null
								)
							),

							// item link
							link: (
								// link itself
								self: (
									bg-color: (
										default: transparent,
										open: $alis-navy-link-bg,
										here: $alis-navy-link-bg,
										active: $alis-navy-link-bg,
										hover: $alis-navy-link-bg
									)
								),					
								// link icon
								icon: (
									font-color:	(
										default: $alis-navy-link-icons,
										open: kt-brand-color(),
										here: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color()
									),
								),		
								// link bullet
								bullet:	(
									dot: (
										bg-color: (
											default: $alis-navy-link-icons,
											open: kt-brand-color(),
											here: kt-brand-color(),
											active: kt-brand-color(),
											hover: kt-brand-color()
										)
									),
									line: (
										bg-color: (
											default: $alis-navy-link-icons,
											open: kt-brand-color(),
											here: kt-brand-color(),
											active: kt-brand-color(),
											hover: kt-brand-color()
										)
									)							
								),					
								// link text
								text:	(
									font-color:	(
										default: $alis-navy-link-text,
										open: #ffffff,
										here: #ffffff,
										active: #ffffff,
										hover: #ffffff	
									)	
								),
								// link arrow
								arrow:	(
									font-color:	(
										default: $alis-navy-link-icons,
										open: kt-brand-color(),
										here: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color()
									)
								)
							),

							// item submenu
							submenu: (
								// submenu self
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),
								// submenu item
								item: (
									// item itself
									self: (
										bg-color: (
											default: null,
											open: null,
											here: null,
											active: null,
											hover: null
										)
									),
									// item link
									link: (
										// link itself
										self: (
											bg-color: (
												default: transparent,
												open: $alis-navy-link-bg,
												here: $alis-navy-link-bg,
												active: $alis-navy-link-bg,
												hover: $alis-navy-link-bg
											)
										),					

										// link icon
										icon:	(
											font-color:	(
												default: $alis-navy-link-icons,
												open: kt-brand-color(),
												here: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										),		

										// link bullet
										bullet:	(
											dot: (
												bg-color: (
													default: $alis-navy-link-icons,
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											),
											line: (
												bg-color: (
													default: $alis-navy-link-icons,
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											)							
										),						
										// link text
										text: (
											font-color:	(
												default: $alis-navy-submenu-link-text,
												open: #ffffff,
												active: #ffffff, 
												hover: #ffffff
											)	
										),
										// link arrow
										arrow:	(
											font-color:	(
												default: $alis-navy-link-icons,
												open: kt-brand-color(),
												here: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										)
									)
								),
								// submenu section
								section: (
									text: (
										font-color: $alis-navy-section-text
									),
									icon: (
										font-color: $alis-navy-section-text
									)
								),

								// submenu separator
								separator: (
									border: 1px solid $alis-navy-section-text
								)
							)
						),

						// root section
						section: (
							text: (
								font-color: $alis-navy-section-text
							),
							icon: (
								font-color: $alis-navy-section-text
							)
						),

						// root separator
						separator: (
							border: 1px solid $alis-navy-section-text
						)
					),

					// minimize mode
					minimize: (
						// root item
						item: (
							// item link
							link: (
								// self
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),		
								// link icon
								icon:	(
									font-color:	(
										default: $alis-navy-minimize-icon-color,
										open: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color()
									)
								)
							)
						)
					),

					// dropdown mode
					dropdown: (
						// dropdown menu item
						item: (
							// submenu
							submenu: (
								// submenu itself
								self: (
									// submenu base styles
									bg-color: #fff,
									box-shadow: 0px 0px 50px 0px rgba(82,63,105,0.15),
								),
								// submenu item
								item: (
									// item itself
									self: (
										bg-color: (
											default: null,
											open: null,
											here: null,
											active: null,
											hover: null
										)
									),
									// item link
									link: (
										// link itself
										self: (
											bg-color: (
												default: null,
												open: $alis-aside-btn-bg,
												here: $alis-aside-btn-bg,
												active: $alis-aside-btn-bg,
												hover: $alis-aside-btn-bg
											)
										),					

										// link icon
										icon:	(
											font-color:	(
												default: lighten($alis-navy-dropdown-icon-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										),		

										// link bullet
										bullet:	(
											dot: (
												bg-color: (
													default: lighten($alis-navy-dropdown-icon-color, 10%),
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											),

											line: (
												bg-color: (
													default: lighten($alis-navy-dropdown-icon-color, 10%),
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											)							
										),						

										// link text
										text: (
											font-color:	(
												default: darken($alis-navy-dropdown-text-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)	
										),

										// link arrow
										arrow:	(
											font-color:	(
												default: lighten($alis-navy-dropdown-icon-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										)
									)
								),

								// submenu section
								section: (
									text: (
										font-color: lighten($alis-navy-dropdown-text-color, 10%)
									),
									icon: (
										font-color: lighten($alis-navy-dropdown-text-color, 12%)
									)
								),
								// submenu separator
								separator: (
									border: 1px solid rgba(#000000, 0.07)
								)
							)
						)		
					)	
				),

				light: (
					// default mode
					default: (
						// menu panel itself
						self: (
							bg-color: kt-get($kt-layout-skins, light)
						),

						// root item
						item: (
							// item itself
							self: (
								bg-color: (
									default: null,
									open: null,
									here: null,
									active: null,
									hover: null
								)
							),
							// item link
							link: (
								// link itself
								self: (
									bg-color: (
										default: null,
										open: $alis-light-link-bg,
										here: $alis-light-link-bg,
										active: $alis-light-link-bg,
										hover: $alis-light-link-bg
									)
								),					
								// link icon
								icon: (
									font-color:	(
										default: $alis-light-link-icons,
										open: kt-brand-color(),
										here: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color()
									),
								),		
								// link bullet
								bullet:	(
									dot: (
										bg-color: (
											default: $alis-light-link-icons,
											open: kt-brand-color(),
											here: kt-brand-color(),
											active: kt-brand-color(),
											hover: kt-brand-color()
										)
									),
									line: (
										bg-color: (
											default: $alis-light-link-icons,
											open: kt-brand-color(),
											here: kt-brand-color(),
											active: kt-brand-color(),
											hover: kt-brand-color()
										)
									)							
								),					
								// link text
								text:	(
									font-color:	(
										default: darken($alis-light-link-text, 6%),
										open: kt-brand-color(),
										here: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color()
									)	
								),
								// link arrow
								arrow:	(
									font-color:	(
										default: $alis-light-link-icons,
										open: kt-brand-color(),
										here: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color()
									)
								)
							),

							// item submenu
							submenu: (
								// submenu self
								self: (
									bg-color: (
										default: #fff,
										open: null,
										active: null,
										hover: null
									)
								),
								// submenu item
								item: (
									// item itself
									self: (
										bg-color: (
											default: null,
											open: null,
											here: null,
											active: null,
											hover: null
										)
									),
									// item link
									link: (
										// link itself
										self: (
											bg-color: (
												default: null,
												open: $alis-aside-btn-bg,
												here: $alis-aside-btn-bg,
												active: $alis-aside-btn-bg,
												hover: $alis-aside-btn-bg
											)
										),					
										// link icon
										icon:	(
											font-color:	(
												default: $alis-light-link-icons,
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										),		
										// link bullet
										bullet:	(
											dot: (
												bg-color: (
													default: $alis-light-link-icons,
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											),
											line: (
												bg-color: (
													default: $alis-light-link-icons,
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											)							
										),						
										// link text
										text: (
											font-color:	(
												default: $alis-light-link-text,
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)	
										),

										// link arrow
										arrow:	(
											font-color:	(
												default: $alis-light-link-icons,
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										)
									)
								),

								// submenu section
								section: (
									text: (
										font-color: lighten($alis-navy-dropdown-text-color, 10%)
									),
									icon: (
										font-color: lighten($alis-navy-dropdown-text-color, 12%)
									)
								),

								// submenu separator
								separator: (
									border: 1px solid rgba(#000000, 0.07)
								)
							)
						),

						// root section
						section: (
							text: (
								font-color: $alis-light-section-text
							),
							icon: (
								font-color: $alis-light-section-text
							)
						),

						// root separator
						separator: (
							border: 1px solid $alis-light-section-text
						)
					),

					// minimize mode
					minimize: (
						// root item
						item: (
							// item link
							link: (
								// self
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),		
								// link icon
								icon:	(
									font-color:	(
										default: $alis-light-link-icons,
										open: kt-brand-color(),
										active: kt-brand-color(),
										hover: kt-brand-color(),
									)
								)
							)
						)
					),

					// dropdown mode
					dropdown: (
						// dropdown menu item
						item: (
							// submenu
							submenu: (
								// submenu itself
								self: (
									// submenu base styles
									bg-color: #fff,
									box-shadow: 0px 0px 50px 0px rgba(82,63,105,0.15),
								),
								
								// submenu item
								item: (
									// item itself
									self: (
										bg-color: (
											default: null,
											open: null,
											here: null,
											active: null,
											hover: null
										)
									),
									// item link
									link: (
										// link itself
										self: (
											bg-color: (
												default: null,
												open: $alis-aside-btn-bg,
												here: $alis-aside-btn-bg,
												active: $alis-aside-btn-bg,
												hover: $alis-aside-btn-bg
											)
										),					
										// link icon
										icon:	(
											font-color:	(
												default: lighten($alis-navy-dropdown-icon-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										),		

										// link bullet
										bullet:	(
											dot: (
												bg-color: (
													default: lighten($alis-navy-dropdown-icon-color, 10%),
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											),
											line: (
												bg-color: (
													default: lighten($alis-navy-dropdown-icon-color, 10%),
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											)							
										),						
										// link text
										text: (
											font-color:	(
												default: darken($alis-navy-dropdown-text-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)	
										),
										// link arrow
										arrow:	(
											font-color:	(
												default: lighten($alis-navy-dropdown-icon-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										)
									)
								),
								// submenu section
								section: (
									text: (
										font-color: lighten($alis-navy-dropdown-text-color, 10%)
									),
									icon: (
										font-color: lighten($alis-navy-dropdown-text-color, 12%)
									)
								),
								// submenu separator
								separator: (
									border: 1px solid rgba(#000000, 0.07)
								)
							)
						)		
					)	
				),

				brand: (
					// default mode
					default: (
						// menu panel itself
						self: (
							bg-color: kt-get($kt-layout-skins, brand)
						),

						// root item
						item: (
							// item itself
							self: (
								bg-color: (
									default: null,
									open: null,
									here: null,
									active: null,
									hover: null
								)
							),

							// item link
							link: (
								// link itself
								self: (
									bg-color: (
										default: transparent,
										open: $alis-brand-link-bg,
										here: $alis-brand-link-bg,
										active: $alis-brand-link-bg,
										hover: $alis-brand-link-bg
									)
								),					
								// link icon
								icon: (
									font-color:	(
										default: $alis-brand-link-icons,
										open: #ffffff,
										here: #ffffff,
										active: #ffffff,
										hover: #ffffff		
									),
								),		
								// link bullet
								bullet:	(
									dot: (
										bg-color: (
											default: $alis-brand-link-icons,
											open: #ffffff,
											here: #ffffff,
											active: #ffffff,
											hover: #ffffff		
										)
									),
									line: (
										bg-color: (
											default: $alis-brand-link-icons,
											open: #ffffff,
											here: #ffffff,
											active: #ffffff,
											hover: #ffffff		
										)
									)							
								),					
								// link text
								text:	(
									font-color:	(
										default: $alis-brand-link-text,
										open: #ffffff,
										here: #ffffff,
										active: #ffffff,
										hover: #ffffff	
									)	
								),
								// link arrow
								arrow:	(
									font-color:	(
										default: $alis-brand-link-icons,
										open: #ffffff,
										here: #ffffff,
										active: #ffffff,
										hover: #ffffff
									)
								)
							),

							// item submenu
							submenu: (
								// submenu self
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),
								// submenu item
								item: (
									// item itself
									self: (
										bg-color: (
											default: null,
											open: null,
											here: null,
											active: null,
											hover: null
										)
									),
									// item link
									link: (
										// link itself
										self: (
											bg-color: (
												default: transparent,
												open: $alis-brand-link-bg,
												here: $alis-brand-link-bg,
												active: $alis-brand-link-bg,
												hover: $alis-brand-link-bg
											)
										),					

										// link icon
										icon:	(
											font-color:	(
												default: $alis-brand-link-icons,
												open: #ffffff,
												here: #ffffff,
												active: #ffffff,
												hover: #ffffff
											)
										),		

										// link bullet
										bullet:	(
											dot: (
												bg-color: (
													default: $alis-brand-link-icons,
													open: #ffffff,
													here: #ffffff,
													active: #ffffff,
													hover: #ffffff
												)
											),
											line: (
												bg-color: (
													default: $alis-brand-link-icons,
													open: #ffffff,
													here: #ffffff,
													active: #ffffff,
													hover: #ffffff
												)
											)							
										),						
										// link text
										text: (
											font-color:	(
												default: $alis-brand-submenu-link-text,
												open: #ffffff,
												active: #ffffff, 
												hover: #ffffff
											)	
										),
										// link arrow
										arrow:	(
											font-color:	(
												default: $alis-brand-link-icons,
												open: #ffffff,
												here: #ffffff,
												active: #ffffff,
												hover: #ffffff
											)
										)
									)
								),
								// submenu section
								section: (
									text: (
										font-color: $alis-brand-section-text
									),
									icon: (
										font-color: $alis-brand-section-text
									)
								),

								// submenu separator
								separator: (
									border: 1px solid $alis-brand-section-text
								)
							)
						),

						// root section
						section: (
							text: (
								font-color: $alis-brand-section-text
							),
							icon: (
								font-color: $alis-brand-section-text
							)
						),

						// root separator
						separator: (
							border: 1px solid $alis-brand-section-text
						)
					),

					// minimize mode
					minimize: (
						// root item
						item: (
							// item link
							link: (
								// self
								self: (
									bg-color: (
										default: null,
										open: null,
										active: null,
										hover: null
									)
								),		
								// link icon
								icon:	(
									font-color:	(
										default: $alis-brand-minimize-icon-color,
										open: #ffffff,
										active: #ffffff, 
										hover: #ffffff
									)
								)
							)
						)
					),

					// dropdown mode
					dropdown: (
						// dropdown menu item
						item: (
							// submenu
							submenu: (
								// submenu itself
								self: (
									// submenu base styles
									bg-color: #fff,
									box-shadow: 0px 0px 50px 0px rgba(82,63,105,0.15),
								),
								// submenu item
								item: (
									// item itself
									self: (
										bg-color: (
											default: null,
											open: null,
											here: null,
											active: null,
											hover: null
										)
									),
									// item link
									link: (
										// link itself
										self: (
											bg-color: (
												default: null,
												open: $alis-aside-btn-bg,
												here: $alis-aside-btn-bg,
												active: $alis-aside-btn-bg,
												hover: $alis-aside-btn-bg
											)
										),					

										// link icon
										icon:	(
											font-color:	(
												default: lighten($alis-navy-dropdown-icon-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										),		

										// link bullet
										bullet:	(
											dot: (
												bg-color: (
													default: lighten($alis-navy-dropdown-icon-color, 10%),
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											),

											line: (
												bg-color: (
													default: lighten($alis-navy-dropdown-icon-color, 10%),
													open: kt-brand-color(),
													here: kt-brand-color(),
													active: kt-brand-color(),
													hover: kt-brand-color()
												)
											)							
										),						

										// link text
										text: (
											font-color:	(
												default: darken($alis-navy-dropdown-text-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)	
										),

										// link arrow
										arrow:	(
											font-color:	(
												default: lighten($alis-navy-dropdown-icon-color, 10%),
												open: kt-brand-color(),
												active: kt-brand-color(),
												hover: kt-brand-color()
											)
										)
									)
								),

								// submenu section
								section: (
									text: (
										font-color: lighten($alis-navy-dropdown-text-color, 10%)
									),
									icon: (
										font-color: lighten($alis-navy-dropdown-text-color, 12%)
									)
								),
								// submenu separator
								separator: (
									border: 1px solid rgba(#000000, 0.07)
								)
							)
						)		
					)	
				)
			)		
		)
	)	
);