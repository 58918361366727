@mixin checkbox {
  .form-check {
    padding-left: 0;
  }
  .form-check-input:not([type=radio]) {
    opacity: 0;
    position: absolute;
  }
  .form-check-label[for] {
    position: relative;
    display: block;
    color: $text-dark;
    background-color: rgba($light-middle-grey, 0.5);
    border-radius: $border-radius;
    $width: 18px;
    $label-padding: $padding/1.5;
    padding: $label-padding $padding;
    padding-left: $padding / 2 + $width + $label-padding + 3px;
    font-size: 14px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 1px + $label-padding;
      left: $label-padding + 3px;
      border: 1px solid $text-light;
      width: $width;
      height: $width;
      border-radius: 3px;
    }
    &:after {
      content: '\f1af';
      font-family: Flaticon2;
      display: block;
      position: absolute;
      top: 3px + $label-padding;
      left: $label-padding + 7px;
      font-size: 10px;
      opacity: 0;
      color: $text-light;
    }
  }
  .radio {
    $label-padding: $padding/1.5;
    input[type=radio] {
      visibility: hidden;
    }
    .form-check-label[for] {
      &:before {
        border-radius: 50%;
      }
      &:after {
        content: '';
        top: 5px + $label-padding;
        left: $label-padding + 7px;
        $width: 10px;
        width: $width;
        height: $width;
        border-radius: 50%;
        background-color: $primary;
      }
    }
  }
  .form-check-input:checked + .form-check-label[for] {
    background-color: rgba($primary, 0.15);
    &:before {
      border: 1px solid $primary;
    }
    &:after {
      opacity: 1;
      color: $primary;
    }
  }
}