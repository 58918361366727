//
// Widget 2
//

// General Mode

.kt-widget-2 {
	display: flex;
    flex-direction: column;
    height: 100%;
	width: 100%;

	.kt-widget-2__content {
		flex-grow: 1;

		.kt-widget-2__item {
			box-shadow: $alis-widget-2-shadow 0px 1px 15px 1px;
			border-radius: 0.5rem;
			padding: 1.4rem;
			margin-top: 3rem;

			.kt-widget-2__item-title {
				font-weight: 500; 
				font-size: 1.1rem;
				color: kt-base-color(label, 4);
				padding-bottom: 0rem;
			}

			.kt-widget-2__item-stats {
				display: flex;
				align-items: center;
				color: kt-base-color(label, 2);
				font-size: 1rem;
				font-weight: 500;

				.kt-widget-2__item-info {
					display: flex;

					.kt-widget-2__item-value {
						padding-left: 0.5rem;
					}
				}

				.kt-widget-2__item-chart {
					padding-left: 1rem;
				}

				@include kt-responsive-range(kt-media-breakpoint(xl), 1550px) {
					align-items: flex-start;
					flex-direction: column;
					padding-top: 0.5rem;

					.kt-widget-2__item-chart {
						padding-left: 0;
					}
				}
			}	
		}
		
		.kt-widget-2__stats {
			display: flex;
			align-items: center;
			margin-top: 2rem;

			> div {
				display: flex;
				align-items: center;

				.kt-widget-2__stats-bullet {
					width: 1rem;
					height: 0.35rem;
					border-radius: 0.3rem;
				}

				.kt-widget-2__stats-text {
					padding-left: 0.6rem;
				}
			}

			.kt-widget-2__stats-author {
				color: kt-base-color(label, 2);
				font-weight: 500;
			}

			.kt-widget-2__stats-product {
				padding-left: 2rem;
				color: kt-base-color(label, 2);
				font-weight: 500;			
			}
		}  	
	}

	.kt-widget-2__chart {
		canvas {
			border-bottom-left-radius: $kt-border-radius;
			border-bottom-right-radius: $kt-border-radius;
		}
	}
}

// Mobile Mode
@include kt-tablet-and-mobile {
	.kt-widget-2 {
		.kt-widget-2__content {
			.kt-widget-2__item {
				margin-top: 1.2rem;

				.kt-widget-2__item-stats {
					align-items: flex-start;
					flex-direction: column;
					padding-top: 0rem;

					.kt-widget-2__item-chart {
						padding-left: 0;
					}
				}
			}	
		}
	}
}