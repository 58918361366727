@import '../../theme/admin/colors';
@import '../../theme/admin/variables';

.application-document {
  
}

.kt-aside--enabled {
  .application-document {
    min-width: auto;
    // @media screen and (min-width: 1500px) {
    //   min-width: 1150px;
    // }
    // @media screen and (max-width: 1499px) {
    //   .application-image {
    //     display: none;
    //   }
    // }
  }
}

.kt-aside--enabled .application-document,
.application-document {
  background-color: white;
  font-size: 14px;
  // $permit-padding: $padding;
  $permit-padding: 0;
  // padding-left: 10px;
  // padding-right: 10px;
  .row,
  .form-row {
    margin-left: 0; // -10px;
    margin-right: 0; // -10px;
  }
  strong {
    display: block;
    font-weight: bold;
  }
  .form-label{
    strong {
      display: inline;
      font-weight: bold;
    }
  }
  .content,
  .header {
    padding: $permit-padding;
    padding-top: 0px;
    .row{
      --bs-gutter-x:0px;
    }
    strong {
      font-size: 14px;
    }
    &.row {
      & > div[class*='col'] {
        margin-top: $permit-padding;
      }
      div[class*='col'] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .header {
    padding: $permit-padding/1.2 $permit-padding;
    padding-top: $permit-padding/1.2 + 5px;
    .form-group {
      margin: 0;
      label {
        margin-top: $padding;
        margin-bottom: 0;
      }
      .form-control-plaintext {
        font-size: 20px;
        font-weight: 700;
        color: $text-dark;
        padding: 0;
      }
    }
    &.row {
      & > div[class*='col'] {
        margin-top: 0;
      }
    }
    background-color: $light-middle-grey;
    strong {
      font-size: 14px;
    }
    .card{
      height: 100%;
    }
    .application-icon {
      position: relative;
      img {
        position: absolute;
        top: 0;
        right: 0;
        filter: invert(96%) sepia(2%) saturate(1210%) hue-rotate(185deg)
          brightness(83%) contrast(83%);
        width: 70px;
        opacity: 0.4;
      }
    }
    // &.accepted {
    // 	background-color: rgba($info, 0.15);
    // 	.permit-icon {
    // 		img {
    // 			filter: invert(56%) sepia(83%) saturate(4672%) hue-rotate(165deg) brightness(96%) contrast(101%);
    // 		}
    // 	}
    // }
    &.issued,
    &.accepted {
      background-color: rgba($primary, 0.15);
      .application-icon {
        img {
          filter: invert(62%) sepia(51%) saturate(339%) hue-rotate(92deg)
            brightness(95%) contrast(94%);
        }
      }
    }
    &.expired {
      background-color: rgba($error, 0.15);
      .application-icon {
        img {
          filter: invert(62%) sepia(98%) saturate(6358%) hue-rotate(344deg)
            brightness(114%) contrast(103%);
        }
      }
    }
    &.no-image {
      .application-icon {
        display: none;
      }
    }
  }
  .nav.nav-tabs {
    $bg-highlight: lighten($light-middle-grey, 2); // lighten($primary, 40);
    background-color: white; // lighten($primary, 40);
    padding: 0 $padding*2.5;
    padding-top: $padding;
    .nav-link.active {
      background-color: $bg-highlight;
    }
    .nav-link, .nav-link.active {
      border: none;
      border-radius: 0;
      padding: $padding/2 $padding;
    }
    border-bottom: none;
    // border-bottom: $padding/2 solid $bg-highlight;
    margin-bottom: 0;
  }
  .tab-content {
    background: lighten($light-middle-grey, 2);
    padding: $padding/2; // $padding*2.5;
  }
  .table-responsive {
    width: 100%;
    overflow-x: auto;
    table.table {
      width: 100%;
      min-width: 840px;
      &.w-100 {
        min-width: 100%;
      }
      thead th,
      thead td,
      tbody th,
      tbody td {
        padding-left: 0;
        padding-right: 0;
      }
      tbody tr:hover {
        background-color: rgba($light-middle-grey, 0.2);
      }
    }
  }
  .footer {
    display: block;
    padding: $permit-padding;
    padding-top: 0;
    hr {
      border: 2.5px $light-middle-grey solid;
      margin-bottom: $padding * 1.5;
    }
    strong {
      font-size: 28px;
    }
    .button .btn {
      min-width: 130px;
    }
  }
  @media screen and (max-width: 1280px) {
    min-width: auto;
    .header {
      strong {
        font-size: 22px;
      }
    }
    .content {
      strong {
        font-size: 16px;
      }
    }
    .content,
    .header,
    .footer {
      padding: $permit-padding/1.5;
      padding-top: 5px;
      &.row {
        & > div[class*='col'] {
          margin-top: $permit-padding/1.5;
        }
      }
    }
    .header {
      &.row {
        & > div[class*='col'] {
          margin-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    min-width: auto;
    .header {
      strong {
        font-size: 22px;
      }
    }
    .content {
      strong {
        font-size: 16px;
      }
    }
    .content,
    .header,
    .footer {
      padding: $permit-padding/2;
      padding-top: 0;
      &.row {
        & > div[class*='col'] {
          margin-top: $permit-padding/2;
        }
      }
    }
    .header {
      &.row {
        & > div[class*='col'] {
          margin-top: 0;
        }
      }
    }
    .application-image {
      display: none;
    }
  }
  @media screen and (max-width: 460px) {
    .footer > div {
      flex-direction: column;
      .button .btn {
        min-width: 100%;
      }
    }
  }
}
