// iPhone autozoom fix
input, select, textarea {
  font-size: 16px;
}

.dropdown-item.active, .dropdown-item:active {
  color: white;
}

.bg-primary-light {
  background-color: lighten($primary, 38);
}

// always show scrollbar on tables in mobile
.react-bootstrap-table {
  overflow: auto;
  @media screen and (max-width: 480px) {
    overflow-x: scroll;
    /* !important is needed sometimes */
    ::-webkit-scrollbar {
      width: 12px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
    background: #41617D !important; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important; 

    }
    ::-webkit-scrollbar-thumb:window-inactive {
    background: #41617D !important; 
    }
  }
}

.min-width-md {
  .react-bootstrap-table {
    table {
      min-width: 640px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.min-vh-full {
  min-height: 250px;
  height: 100%;
}

.font-weight-black {
  font-weight: 700;
}

.alert {
  a {
    // color: white;
    font-weight: 500;
    text-decoration: underline !important;
    &:hover {
      // color: white;
      text-decoration: underline !important;
    }
  }
  &.alert-light {
    background-color: $light-middle-grey;
  }
  &.alert-light-info {
    background-color: rgba($info, 0.15);
  }
}

@keyframes toast {
  from {margin-top:-150px;opacity: 0;}
  to {margin-top: 0;opacity: 1;}
}
@keyframes toast_close {
  from {margin-top:0;opacity: 1;}
  to {margin-top: 200px;opacity: 0;}
}

.toast {
  animation-name: toast;
  animation-duration: .5s;
  &.toast-close {
    animation-name: toast_close;
  }
  .toast-header {
    button.close {
      margin-bottom: 0 !important;
      color: white;
    }
  }
}

.kt-header .kt-header__topbar .kt-header__topbar-item.show.kt-header__topbar-item--user .kt-header__topbar-user {
  border-radius: $border-radius*2;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user {
  border-radius: $border-radius*2;
}

.kt-header .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--rel{
  &.kt-menu__item--submenu:hover {
    & > .kt-menu__link {
      background-color: rgba($primary, 0.2);
    }
  }
  &:not(.kt-menu__item--submenu) {
    &:hover {
      & > .kt-menu__link {
        background-color: transparent;
        .kt-menu__link-text {
          color: $text-dark;
          transition-duration: $duration;
        }
        &:hover {
          .kt-menu__link-text {
            color: $primary;
            transition-duration: $duration;
          }
        }
      }
    }
  }
}

.kt-header .kt-nav__custom.kt-space-between {
  .btn-label-brand {
    &:hover {
      color: white;
    }
  }
}

.kt-page, .default-layout, .default-layout>.kt-grid {
  &, .kt-portlet, .kt-portlet__body, .content-page > div, .content-page > div > div {
    height: 100%;
  }
  .kt-portlet__body {
    display: block;
  }
  .kt-content {
    &.form-page > div {
      align-self: center;
      max-width: 1024px;
      width: 100%;
    }
  }
  .content-page {
    // margin-top: $padding;
    & > .kt-portlet {
      min-height: calc(100% - 20px);
    }
    // & > .hide-white-bg {
    //   background-color: transparent;
    // }
  }

  &.centered {
    .content-page {
      &.kt-grid__item--fluid {
        flex: 0 !important;
      }
      & > .kt-portlet {
        box-shadow: none;
        height: auto;
        min-height: auto;
        width: 100%;
        & > .kt-portlet__body {
          padding: 0;
        }
      }
    }
    .kt-content {
      display: flex;
      justify-content: center;
      align-self: center;
    }
  }
}

.kt-subheader .kt-subheader__main .kt-subheader__title {
  font-size: 21px;
}
.kt-aside {
  .kt-aside-menu {
    background: linear-gradient(178.91deg, rgba(255, 255, 255, 0) 40.52%, rgba(150, 203, 200, 0.25) 99.81%);
    margin-bottom: 0;
    .kt-menu__nav {
      padding: 0;
      & > .kt-menu__section {
        margin: auto;
      }
    }
  }
  .forest {
    background-color: #367931;
  }
}

@media screen and (max-width: 1024px) {
  .kt-aside {
    .kt-aside-menu {
      background: none;
    }
    .forest {
      display: none;
    }
  }
}

.kt-footer {
  background-color: transparent;
}
.accordion.accordion-outline .card {
  .card-title {
    line-height: 130%;
  }
  .card-title:not(.collapsed), .card-body-wrapper {
    line-height: 175%;
    background-color: rgba($primary, 0.2);
  }
  .card-body-wrapper > .card-body {
    color: $text-dark !important;
  }
  .card-header .card-title.collapsed {
    padding-bottom: 1.2rem;
  }
}

#root {
  width: 100%;
}

.kt-aside__brand-logo img {
  height: 40px;
}

.kt-header-mobile__logo img {
  height: 40px;
}

.table-responsive {
  overflow: auto;
  table.table {
    // TODO: check if this is OK!!! nad replace it to min content
    min-width: 1024px;
    &.min-width-auto {
      // TODO: check if this is needed, it's now for L06.1 leidimas form view table size... later perhaps unnecessary
      min-width: auto;
    }
  }
}

.kt-portlet__body {
  & > .nav.nav-tabs {
    border-bottom: 0;
    .nav-link.active, .nav-item.show .nav-link, .nav-item {
      border: none;
      margin-bottom: 0;
    }
    .nav-link {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .nav-link.active {
      background-color: darken($light-background, 2);
    }
    .nav-link:hover:not(.active) {
      background-color: lighten($primary, 40);
      border: none
    }
    .nav-link:focus {
      border: lighten($primary, 35);
    }
  }
}

@media print {
  .table-responsive {
    table.table {
      min-width: auto;
    }
  }
  .no-print {
    display: none;
  }
}

.filter-form {
  margin-bottom: 15px;
  &+br {
    display: none;
  }
  &>.kt-portlet {
    margin: -25px;
    padding: 25px;
    margin-bottom: 5px;
    background-color: rgb(241, 244, 253); // #e1e1ef; // rgba(#e1e1ef, 0.2);
    box-shadow: none;
    margin-bottom: 10px;
    &>.kt-portlet__body {
      padding: 0;
    }
  }
  .kt-radio, .kt-checkbox {
    background-color: rgba(#e1e1ef, 0.3); /**/
    width: 100%;
    padding-left: 40px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    span {
      margin-top: 10px;
      margin-left: 14px;
    }
  }
}

.kt-radio-warning, .kt-radio-error {
  color: $danger;
  margin-left: -30px;
}

.kt-aside-menu.kt-scroll {
  height: 100vh;
  overflow: hidden;
}

.btn-primary.disabled, .btn-primary:disabled, .btn-secondary.disabled, .btn-secondary:disabled {
  cursor: not-allowed;
  $bg-color: darken($light-background, 10);
  background-color: $bg-color;
  border-color: $bg-color;
  color: $text-dark;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: white;
  background-color: darken($primary, 10);
}

.not-disabled .form-check-input[disabled] ~ .form-check-label, .not-disabled .form-check-input:disabled ~ .form-check-label{  
  opacity: 1;
}


.not-disabled .form-check-input:disabled { 
  opacity: 1;
}



@media (max-width: 1024px) {
  .filter-form {
    margin-bottom: 30px;
    &>.kt-portlet {
      margin: -15px;
    }
  }
  .kt-aside__footer.kt-grid__item {
      display: none;
  }
  .kt-aside-menu.kt-scroll {
    height: auto;
  }
  .kt-aside--on .kt-aside-close {
    left: 276px;
    &:before {
      content: ' ';
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .kt-aside--on {
    .kt-grid__item.kt-page {
      &:after {
        content: ' ';
        height: 100%;
        width: 100%;
        position: fixed;
        background-color: rgba(black, 0.5);
        z-index: 1000;
        top: 0;
      }
    }
  }
}