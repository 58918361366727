.page-layout {
  min-width: 100%;
  .kt-header {
    left: 0 !important;
  }
  .top-menu, .bottom-menu {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: $alis-header-base-bg;
    .spacer {
      flex-grow: 1;
    }
    .logout {
      background: $success;
    }
    .login {
      background: rgba(white, 0.3);
    }
    li {
      padding: 25px 10px;
      a {
        color: white;
      }
    }
  }
  .bottom-menu {
    background-color: rgba(black, 0.1);
    li a {
      color: black;
    }
  }
  article {

  }
}