@import '../../theme/default/colors';
@import '../../theme/default/variables';
@import '../../theme/default/checkbox';

.drag-and-drop {
  // d-flex flex-grow-1 justify-content-center align-items-center
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  $bg-color: lighten($light-middle-grey, 5);
  border-radius: $border-radius;
  background-color: $bg-color;
  color: darken($bg-color, 25);
  border: 1px dashed darken($bg-color, 25);
  padding: $padding/3;
  &.dragging {
    $bg-color: $info;
    border: 2px solid rgba($bg-color, .5);
    background-color: rgba($bg-color, .2);
    color: darken($bg-color, 25);
  }
}

.drop-file {
  margin-right: $padding/2;
}
// .drop-button {}

@media screen and (max-width: 768px) {
  .drop-file {
    margin-right: 0;
    text-align: center;
  }
  .drop-file, .drop-button {
    margin-top: $padding/2;
    width: 100%;
  }
}