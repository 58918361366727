// **************************************************
// DO NOT EDIT THIS FILE!!! Use _colors.scss instead!
// **************************************************

// KT general colors

$alis-database-base-color: #f9fbfe !default;
$alis-switch-base-color: #e8ebf1 !default;
$alis-grid-title-color: #5d5b6f !default;
$alis-iconbox-title-color: #464457 !default;
$alis-nav-hover-color: #f4f4f9 !default;
$alis-notes-content-color: #f7f8fC !default;
$alis-notification-title-color: $alis-grid-title-color !default;
$alis-notification-item-color: #b3b1c2 !default;
$alis-user-card-bg-color: #f9fafe !default;
$alis-offcanvas-panel-shadow-color: rgba(82,63,105,0.13) !default;;
$alis-quick-search-icon-color: #f0f0f4 !default;
$alis-sticky-toolbar-nav-color: $alis-nav-hover-color !default;
$alis-subheader-base-color: #959cb6 !default;
$alis-subheader-input-group-color: #f3f4f6 !default;
$alis-subheader-input-text-color: #b1b5cb !default;
$alis-code-color: #e83e8c !default;
$alis-demo-icon-color: #aaa !default;
$alis-divider-color: #ebecf1 !default;
$alis-aside-bg: #f4f5f8 !default;

// KT widgets

$alis-widget-2-shadow: rgba(69, 65, 78, 0.06) !default;
$alis-widget-3-desc-color: #9686fa !default;
$alis-widget-22-date-color: #3d4465 !default;

// KT profile

$alis-profile-base-font: #b2afc6 !default;
$alis-profile-main-info-name: #464457 !default;
$alis-profile-contact-item-text: $alis-profile-base-font !default;
$alis-profile-main-info-position: $alis-profile-contact-item-text !default;
$alis-profile-contact-icon-whatsup: #45cb9a !default;
$alis-profile-contact-icon-twitter: #18c1f8 !default;
$alis-profile-stats-label-color: $alis-grid-title-color !default;
$alis-profile-stats-chart-color: $alis-profile-contact-item-text !default;

// bootstrap components, framework

$alis-accordion-light-border-color: #eeeef4 !default;
$alis-accordion-collapsed-color: $alis-iconbox-title-color !default;
$alis-accordion-collapsed-border-color: #efeff0 !default;
$alis-accordion-card-body-color: #8d8c9b !default;
$alis-button-base-color: #999 !default;
$alis-pagination-link-color: #928eaa !default;
$alis-table-light-tr-color: #9392a0 !default;
$alis-tooltip-dark-bg-color: #2c2e3e !default;

// bootstrap variables, that are directly hardcoded by KT theme

$alis-bootstrap-secondary: #e1e1ef !default;
$alis-bootstrap-light: #f8f9fa !default;
$alis-bootstrap-dark: #343a40 !default;
$alis-bootstrap-btn-secondary-hover-bg-color: $alis-aside-bg !default;
$alis-bootstrap-table-bg: $alis-bootstrap-btn-secondary-hover-bg-color !default;

// KT plugins

$alis-datatable-base-color: #f3f4f8 !default;
$alis-datatable-dtr-title-border: #efefef !default;
$alis-dropzone-item-bg: #f8f9fc !default;
$alis-duallistbox-button-bg: #f2f3f7 !default;
$alis-duallistbox-item-selected-bg: $alis-duallistbox-button-bg !default;
$alis-quill-bg: #333 !default;
$alis-quill-color: #f8f8f2 !default;
$alis-tagify-outside: #ddd !default;

// KT layout

$alis-offcanvas-close-icon: #968ad4 !default;

$alis-brand-aside-perfect-scrollbar-skin: #798df1 !default;
$alis-brand-aside-footer: #475fd2 !default;
$alis-brand-aside-btn-color: #889dfc !default;
$alis-brand-aside-btn-hover: $alis-brand-aside-footer !default;

$alis-light-aside-perfect-scrollbar-skin: #e0e2f0 !default;
$alis-light-aside-footer: #f6f7fd !default;
$alis-light-aside-btn-color: #99a7df !default;
$alis-light-aside-btn-hover: $alis-brand-aside-footer !default;

$alis-navy-aside-perfect-scrollbar-skin: #637099 !default;
$alis-navy-aside-footer: #1f2231 !default;
$alis-navy-aside-btn-color: #364b8b !default;
$alis-navy-aside-btn-hover: #191b27 !default;

$alis-aside-btn-bg: #f0f0f6 !default;
$alis-aside-close-button-bg: #f8f8fb !default;
$alis-aside-secondary-close-button-bg: $alis-aside-bg !default;
$alis-aside-secondary-icon-bg: $alis-aside-bg !default;

$alis-navy-link-bg: #1f2433 !default;
$alis-navy-link-text: #c0c4d1 !default;
$alis-navy-link-icons: #687393 !default;
$alis-navy-submenu-link-text: #989eb3 !default;
$alis-navy-section-text: #606a8c !default;
$alis-navy-minimize-icon-color: #7c87a6 !default;
$alis-navy-dropdown-icon-color: #8c8ea4 !default;
$alis-navy-dropdown-text-color: #868aa8 !default;
$alis-navy-burger-icon-skin: #484f66 !default;

$alis-light-link-bg: #f6f7fd !default;
$alis-light-link-text: #3d4465 !default;
$alis-light-link-icons: #c4cff9 !default;
$alis-light-section-text: #a1a8c3 !default;
$alis-light-burger-icon-skin: #b4b8ce !default;

$alis-brand-link-bg: #475fd2 !default;
$alis-brand-link-text: #eff2ff !default;
$alis-brand-submenu-link-text: #bac5fb !default;
$alis-brand-link-icons: #889dfc !default;
$alis-brand-section-text: #8ea1ff !default;
$alis-brand-minimize-icon-color: #bcc8ff !default;
$alis-brand-burger-icon-skin: #8ca0fe !default;
$alis-brand-bg: #272631 !default;
$alis-brand-aside-toggler: #4e4c5f !default;

// KT header

$alis-brand-header-item-hover-bg: #5971e7 !default;
$alis-brand-header-bg: #536be2 !default;
$alis-brand-header-hor-icon: #889dfc !default;

$alis-light-header-item-icon-color: #b4b8ce !default;
$alis-light-header-hover-bg: #f0f3ff !default;
$alis-light-header-bottom-border: #eff0f6 !default;
$alis-light-header-link-text: #6c7293 !default;
$alis-light-header-hor-icon: #c8cde8 !default;
$alis-light-header-welcome: #959cb6 !default;

$alis-navy-header-icon-font-color: #6e7899 !default;
$alis-navy-header-hover-bg: #282f48 !default;
$alis-navy-header-bg: #536be2 !default;
$alis-navy-header-link-text: #c0c4d1 !default;
$alis-navy-header-hor-icon: #677191 !default;
$alis-navy-header-welcome: #6b7594 !default;
$alis-navy-header-topbar-border: #2e3448 !default;

// KT header config

$alis-header-base-bg: #2b2a35 !default;
$alis-header-toolbar-toggler-color: #4e4c5f !default;

$alis-header-light-link-bg: #f0f3ff !default;
$alis-header-light-icon-font-color: #cacad2 !default;
$alis-header-light-submenu-link-text: #5f6281 !default;
$alis-header-light-separator-border: #f6f6f9 !default;

$alis-header-dark-link-bg: #6548e5 !default;
$alis-header-dark-link-text: #f8f8fb !default;
$alis-header-light-icon-font-color: #8c8ea4 !default;

$alis-header-default-link-text: #868aa8 !default;
$alis-header-default-offcanvas-close: #968ad4 !default;
$alis-header-default-separator-border: #f6f6f9 !default;
$alis-header-default-submenu-link-text: #6b6f8d !default;

$alis-header-mobile-toggler-icon: #4e4c5f !default;
$alis-header-topbar-welcome: #636177 !default;
$alis-header-topbar-languages-bg: #212029 !default;
$alis-header-topbar-tablet-mobile-bg: #2b2a35 !default;

// OTHER KT variables

// KT Blog

$kt-blog-grid-font-color: #918ea7 !default;
$kt-blog-list-font-color: #918ea7 !default;
$alis-blog-list-title-color: #464457 !default;
$alis-blog-list-meta-border: #e9e9f1 !default;
$kt-blog-post-title-color: #464457 !default;
$kt-blog-post-font-color: #918ea7 !default;
$kt-blog-post-content-color: #7b7986 !default;
$alis-blog-post-date-border: #e9e9f1 !default;

// KT Error 404

$kt-error404-v1-title-color: #fb2f73 !default;
$kt-error404-v3-btn-color: #f7098f !default;
$kt-error404-v4-btn-color: #f7098f !default;

// KT inbox

$kt-inbox-icon-color: lighten(#8e96b8, 6%) !default;
$kt-inbox-hover-bg: #f2f3f7 !default;

// KT invoice

$kt-invoice1-font-color: #aaabf7 !default;
$kt-invoice1-body-title: #babac6 !default;
$kt-invoice1-body-content: #464457 !default;
$kt-invoice1-body-table-th-border: #eaebec !default;

// KT login

$alis-login-v1-action-btn: #ff146c !default;
$alis-login-v1-options-btn: #1f2375 !default;
$alis-login-v1-options-btn-hover: #1f2164 !default;

$alis-login-v2-signup-span: #9d9fb1 !default;
$alis-login-v2-title: #636576 !default;
$alis-login-v2-description: #a5a7bb !default;
$alis-login-v2-facebook: #3b5998 !default;
$alis-login-v2-twitter: #1da1f2 !default;
$alis-login-v2-google: #ea4335 !default;
$alis-login-v2-link: #9c9fb1 !default;

// KT Pricing

$kt-pricing-v1-base-color: #b2afc6 !default;
$kt-pricing-v1-label-color: #9b98af !default;
$kt-pricing-v1-currency-color: #cccce3 !default;
$alis-pricing-v1-border: #ebebf5 !default;
$alis-pricing-v1-base-color: #918ea7 !default;
$alis-pricing-v1-currency-color: #cccce3 !default;
$alis-pricing-v1-border: #f0f0f6 !default;
$alis-pricing-v1-btn-color: #7f87aa !default;
$alis-pricing-v1-btn-active-bg: #b4aad3 !default;
$alis-pricing-v1-iconbox-icon: #8388a4 !default;

// KT Wizards

$kt-wizard-v1-step-bg: #f6f6f9 !default;
$kt-wizard-v1-step-number: #9896a6 !default;
$kt-wizard-v1-font: #918ea7 !default;
$alis-wizard-border: #eeeef4 !default;

$kt-wizard-v2-step-bg: #d2d0e0 !default;
$kt-wizard-v2-step-number: #9896a6 !default;
$kt-wizard-v2-font: #918ea7 !default;

$kt-wizard-v3-step-bg: #f6f6f9 !default;
$kt-wizard-v3-step-font: kt-base-color(label, 1) !default;
$kt-wizard-v3-label-font: kt-base-color(label, 2) !default;

// State colors
$kt-state-colors: (
    // Keen states
    brand: (
        base: #5d78ff,
        inverse: #ffffff
    ),
    metal: (
        base: #d3dae6,
        inverse: #586272
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c
    ),
    dark: (
        base: lighten(#4c467a, 12%),
        inverse: #ffffff
    ),
    accent: (
        base: #00c5dc,
        inverse: #ffffff
    ),
    focus: (
        base: #9816f4,
        inverse: #ffffff
    ),

    // Bootstrap states
    primary: (
        base: #5867dd,
        inverse: #ffffff
    ),
    success: (
        base: #1dc9b7,
        inverse: #ffffff
    ),
    info: (
        base: #5578eb,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #fd397a,
        inverse: #ffffff
    )
);

$kt-base-colors: (
    label: (
        1: #a2a5b9,
        2: #74788d,
        3: #595d6e,
        4: #48465b
    ),
    shape: (
        1: #f0f3ff,
        2: #e8ecfa,
        3: #93a2dd,
        4: #646c9a
    ),
    grey: (
        1: #f7f8fa, //#f4f5f8
        2: #ebedf2,
        3: darken(#ebedf2, 3%),
        4: darken(#ebedf2, 6%)
    )
) !default;

$kt-social-colors: (
    facebook: (
        base: #3b5998,
        inverse: #ffffff
    ),
    google: (
        base: #dc4e41,
        inverse: #ffffff
    ),
    twitter: (
        base: #4AB3F4,
        inverse: #ffffff
    ),
    instagram: (
        base: #517fa4,
        inverse: #ffffff
    ),
    youtube: (
        base: #b31217,
        inverse: #ffffff
    ),
    linkedin: (
        base: #0077b5,
        inverse: #ffffff
    ),
    skype: (
        base: #00aff0,
        inverse: #ffffff
    )
) !default;

// Page
$kt-page-bg-color: #f2f3f8;

// Layout Skins
$kt-layout-skins: (
	brand: #4d65dc,
	navy: #242939,
	light: #ffffff
);

// $kt-font-color: (
//     text: #646c9a,
//     link: (
//         default: kt-state-color(brand),
//         hover: darken(kt-state-color(brand), 6%)
//     )
// ) !default;
