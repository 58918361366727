$datepicker__background-color: white;
$datepicker__border-color: none;
$datepicker__highlighted-color: white;
$datepicker__muted-color: $primary;
$datepicker__selected-color: $primary;
$datepicker__text-color: map-get($kt-font-color, 'text');
$datepicker__header-color: map-get($kt-font-color, 'text');
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__border-radius: 0.3rem;
$datepicker__day-margin: 0.2rem;
$datepicker__font-size: 1rem;
$datepicker__font-family: inherit;
$datepicker__item-size: 2.5rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: darken($datepicker__text-color, 2) !important;
}
.react-datepicker__current-month {
  text-transform: capitalize;
}
.react-datepicker__header, .react-datepicker__navigation {
  margin-top: 10px !important;
  padding-top: 0;
}
.react-datepicker__month-container {
  padding: 0 10px;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
}
.react-datepicker__day-name {
  font-weight: 500 !important;
}
.react-datepicker__day--today{
  font-weight: 300 !important;
  &:not(.react-datepicker__day--selected) {
    background: rgba($primary, 0.1) !important;
    color: $datepicker__text-color !important;
  }
}
.react-datepicker__close-icon::after {
  width: 19px !important;
  height: 19px !important;
  padding-top: 3px !important;
  padding-right: 3px !important;
  background-color: rgba($primary, 0.2) !important;
  color: $primary !important;
}
.was-validated .form-control:valid, .form-control.is-valid {
  background-image: none !important;
  border-color: #e2e5ec !important;
  &:focus {
    border-color: #9aabff !important;
  }
}
.react-datepicker-popper {
  z-index: 2;
}